<template>
  <hcc-modal
    :border="false"
    :clickToClose="false"
    class="modal"
    name="error-modal">
    <template #header>
    <div class="modal-header">
      <span>
      ERROR
      </span>
    </div>
    </template>
    <span class="modal-text">{{ message }}</span>
    <template #footer>
    <div class="modal-footer">
      <hcc-button color="alert" @click="handleErrorConfirm">
      Acceptar
      </hcc-button>
    </div>
    </template>
</hcc-modal>
</template>

<script>
export default ({
  props: {
    message: {
      required: true,
    },
  },
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
  },
  methods: {
    handleErrorConfirm() {
      this.$modal.hide('error-modal');
      this.$emit('error');
    },
  },
});
</script>

<style scoped lang="scss">
  @import '~styles/components/_error-modal.scss';
</style>
